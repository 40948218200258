.dropdown {
    >button {
      position: relative;
      height: 31px;
      font-size: 12px;
      color: #fff;
      font-weight: 400 !important;


      background: #1D1D2A;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);


      border-radius: 10px !important;

      &:after {
        display: none;
      }
    }

    .DropdownMenu {
      background: #1D1D2A;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
      margin-top: 10px;
      padding-bottom: 30px !important;

      .dropIcn {
        path {
          fill: #1D1D2A;
          stroke: #1D1D2A;
        }
      }

      /* ol {
        li {
          border-bottom: 1px solid #5db8ff3b;
        }
      } */
    }
  }